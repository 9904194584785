<template>
  <DgPanel :header="$t('dsr.create.title')" :class="['dsr-create', { 'dsr-create--saving': isLoading }]">
    <DgLoadingIndicator :is-loading="isLoading" :label="$t('dsr.create.savingLabel')" />
    <form @submit.prevent="onCreateDataSubjectRequest" autocomplete="off">
      <DgSelect
        data-testId="dsr-create-form-request-type"
        :label="$t('dsr.create.labels.requestType')"
        :placeholder="$t('dsr.create.placeholders.requestType')"
        :options="dataSubjectRequestTypes"
        :initial-value="form.dataSubjectRequestType && form.dataSubjectRequestType.label"
        @input="onDataSubjectRequestTypeInput"
        required
      />
      <DgSelect
        data-testId="dsr-create-form-data-subject-type"
        :label="$t('dsr.create.labels.dsrType')"
        :placeholder="$t('dsr.create.placeholders.dsrType')"
        :options="dataSubjectTypes"
        :initial-value="form.dataSubjectType && form.dataSubjectType.label"
        @input="onDataSubjectTypeInput"
        required
      />
      <DgInput
        data-testId="dsr-create-form-first-name"
        :label="$t('dsr.create.labels.firstName')"
        :placeholder="$t('dsr.create.placeholders.requestType')"
        :errorMessage="$t('dsr.create.errors.firstName')"
        v-model="form.firstName"
        required
      />
      <DgInput
        data-testId="dsr-create-form-last-name"
        :label="$t('dsr.create.labels.lastName')"
        :placeholder="$t('dsr.create.placeholders.lastName')"
        :errorMessage="$t('dsr.create.errors.lastName')"
        v-model="form.lastName"
        required
      />
      <DgEmail
        data-testId="dsr-create-form-email"
        :label="$t('dsr.create.labels.email')"
        :placeholder="$t('dsr.create.placeholders.email')"
        v-model="form.email"
        :errorMessage="$t('dsr.create.errors.email')"
        required
      />
      <DgInput
        data-testId="dsr-create-form-details"
        textarea
        :label="$t('dsr.create.labels.furtherDetails')"
        :placeholder="$t('dsr.create.placeholders.furtherDetails')"
        v-model="form.furtherDetails"
      />
      <DatePicker
        data-testId="dsr-create-form-date-picker"
        v-model="form.createdAt"
        mode="dateTime"
        @input="dateTimeChange"
        :max-date="new Date()"
        :popover="dateTimePopover"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div :class="[`dg-form-input dg-input dg-input-text`]">
            <label for="date-time">
              {{ $t("dsr.create.labels.dsrReceivedDate") }}
              <span class="required">*</span>
            </label>
            <input id="date-time" name="date-time" class="dg-input" :value="inputValue" v-on="inputEvents" />
            <p v-if="dateTimeError" class="error-message">{{ $t("dsr.create.errors.dateTime") }}</p>
          </div>
        </template>
      </DatePicker>

      <dg-button :disabled="isFormInValid || isLoading" :loading="isLoading" data-testId="dsr-finalize-creation-btn">
        {{ $t("dsr.create.btnLabel") }}
      </dg-button>
    </form>
  </DgPanel>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import validator from "email-validator";
import { Structural, Basic, Form } from "@/dgui-customer-components/";

export default {
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        furtherDetails: "",
        createdAt: "",
        dataSubjectRequestType: null,
        dataSubjectType: null,
      },
      isLoading: false,
      dateTimeError: false,
      dateTimePopover: {
        placement: "top-start",
        visibility: "click",
      },
    };
  },
  components: {
    DgPanel: Structural.DgPanel,
    DgInput: Form.DgInput,
    DgSelect: Form.DgSelect,
    DgLoadingIndicator: Basic.DgLoadingIndicator,
    DgEmail: Form.DgEmail,
    DatePicker,
  },
  computed: {
    ...mapGetters(["companyData"]),
    dataSubjectRequestTypes() {
      return [
        { code: 1, label: this.$t("dsr.create.dsrTypes.newsletter") },
        { code: 2, label: this.$t("dsr.create.dsrTypes.generalInfo") },
        { code: 3, label: this.$t("dsr.create.dsrTypes.updateData") },
        { code: 4, label: this.$t("dsr.create.dsrTypes.eraseData") },
        { code: 5, label: this.$t("dsr.create.dsrTypes.restrictProcessing") },
        { code: 6, label: this.$t("dsr.create.dsrTypes.transferData") },
        { code: 7, label: this.$t("dsr.create.dsrTypes.objectProcessing") },
        { code: 8, label: this.$t("dsr.create.dsrTypes.enquiry") },
      ];
    },
    dataSubjectTypes() {
      return [
        { code: 11000, label: this.$t("dsr.create.dataSubjectTypes.employee") },
        { code: 11001, label: this.$t("dsr.create.dataSubjectTypes.formerEmployee") },
        { code: 11002, label: this.$t("dsr.create.dataSubjectTypes.customer") },
        { code: 11003, label: this.$t("dsr.create.dataSubjectTypes.interestedPerson") },
        { code: 11005, label: this.$t("dsr.create.dataSubjectTypes.contractor") },
        { code: 11006, label: this.$t("dsr.create.dataSubjectTypes.newsletter") },
      ];
    },
    isFormInValid() {
      const { firstName, lastName, email, createdAt, dataSubjectRequestType, dataSubjectType } = this.form;
      if (
        firstName &&
        lastName &&
        email &&
        createdAt &&
        dataSubjectRequestType &&
        dataSubjectType &&
        this.emailValidator(email)
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onCreateDataSubjectRequest() {
      const { token } = this.companyData;
      const form = this.form;

      this.isLoading = true;

      this.$http
        .post(`/api1/v2/data_subject_requests?token=${token}`, {
          data_subject_request: {
            data_subject_request_type_id: form.dataSubjectRequestType.code,
            relation_id: form.dataSubjectType.code,
            first_name: form.firstName,
            last_name: form.lastName,
            email: form.email,
            further_details: form.furtherDetails,
            created_at: form.createdAt,
            created_manually: true,
          },
        })
        .then(response => {
          this.$router.push({ name: "DSRDashboard" });
        })
        .catch(error => {
          this.apiCatch(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onDataSubjectRequestTypeInput(option) {
      this.form.dataSubjectRequestType = option;
    },
    onDataSubjectTypeInput(option) {
      this.form.dataSubjectType = option;
    },
    emailValidator(value) {
      return validator.validate(value);
    },
    dateTimeChange(selectedDateTime) {
      this.form.createdAt = selectedDateTime ? new Date(selectedDateTime).toUTCString() : "";
      this.dateTimeError = !Boolean(selectedDateTime);
    },
  },
};
</script>
<style lang="scss">
.dsr-create {
  &--saving {
    opacity: 0.7;
    pointer-events: none;
  }

  .error-message {
    font-size: 12px;
    margin-top: 0;
  }
}
</style>
